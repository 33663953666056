<template>
  <v-menu
    offset-y
  >
    <v-btn
      slot="activator"
      small
      depressed
      outline
      style="border-color: white !important; color: gray;"
    >
      <country-flag
        :country="geo"
        style="margin-right: 3px"
      />
      <v-icon>
        fa fa-angle-down
      </v-icon>
    </v-btn>
    <v-list
      v-for="c in available_countries"
      :key="c"
    >
      <v-list-tile
        :to="{ name: 'candidate:landing', params: { geo: c } }"
      >
        <country-flag :country="c" />
      </v-list-tile>
    </v-list>
  </v-menu>
</template>

<script>
import CountryFlag from 'vue-country-flag';
import ValidCountriesComputedMixin from '@/components/mixins/geo/ValidCountriesComputedMixin.vue';

export default {
  name: 'GeoCountrySelector',
  components: {
    CountryFlag,
  },
  mixins: [
    ValidCountriesComputedMixin,
  ],
  props: {
    geo: {
      type: String,
      required: true,
    },
  },
  computed: {
    available_countries() {
      return this.validCountries.filter(val => (val !== this.geo));
    },
  },
};
</script>
