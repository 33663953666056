<template>
  <v-snackbar
    :value="consentSnackbar"
    :bottom="true"
    :right="true"
    :timeout="timeout"
    :multi-line="true"
  >
    Para que podamos brindarte una mejor experiencia desde nuestro portal, activa las
    notificaciones de correos.
    <v-btn
      color="info"
      flat
      @click="ignoreConsent"
    >
      Ignorar
    </v-btn>
    <v-btn
      color="info"
      flat
      @click="acceptConsent"
    >
      Activar
    </v-btn>
  </v-snackbar>
</template>

<script>
import axios from 'axios';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';

export default {
  mixins: [JWTConfigMixin],
  props: {
    timeout: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    consentSnackbar() {
      return !(this.$store.state.ignoreConsentNotice || this.$store.state.notificationConsent);
    },
  },
  methods: {
    closeSnackbar() {
      this.$store.commit('consentSnackbarDown');
    },
    ignoreConsent() {
      const fd = new FormData();
      fd.append('ignore_consent_notice', 'True');
      axios.patch(this.$store.state.endpoints.candidates.getSelf,
        fd, this.jwtConfig).then(() => {
        this.$store.commit('adminSnackbarUp', 'Para recibir notificaciones en el futuro, entrega tu consentimiento en el perfil.');
        this.$store.state.ignoreConsentNotice = true;
      }).catch(err => {
        this.fieldErrors = err.response.data;
        this.isEditing = true;
      });
    },
    acceptConsent() {
      const fd = new FormData();
      fd.append('ignore_consent_notice', 'True');
      fd.append('notification_consent', 'True');
      axios.patch(this.$store.state.endpoints.candidates.getSelf,
        fd, this.jwtConfig).then(() => {
        this.$store.commit('adminSnackbarUp', 'A partir de ahora te enviaremos notificaciones a tu correo electrónico.');
        this.$store.state.ignoreConsentNotice = true;
        this.$store.state.notificationConsent = true;
      }).catch(err => {
        this.fieldErrors = err.response.data;
        this.isEditing = true;
      });
    },
  },
};
</script>
