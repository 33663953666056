import { render, staticRenderFns } from "./ToolbarLinksDesktopLogged.vue?vue&type=template&id=095f42b8&scoped=true&"
import script from "./ToolbarLinksDesktopLogged.vue?vue&type=script&lang=js&"
export * from "./ToolbarLinksDesktopLogged.vue?vue&type=script&lang=js&"
import style0 from "./ToolbarLinksDesktopLogged.vue?vue&type=style&index=0&id=095f42b8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "095f42b8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAvatar,VBtn,VDivider,VIcon,VList,VListTile,VListTileContent,VListTileTitle,VMenu,VToolbarItems})
