<template>
  <v-app id="inspire">
    <Toolbar />
    <v-content>
      <router-view />
    </v-content>

    <template>
      <slot name="drawer" />
    </template>

    <template>
      <!-- <slot name="footer">
        <v-footer
          color="primary"
          class="white--text"
          app
        >
          <template v-if="$vuetify.breakpoint.smAndUp">
            <a
              href="https://mandomedio.com/outplacement-personas/"
              style="color: white; margin: 0 0.5% 0 0.5%"
              target="_blank"
            >
              Asesoría en Empleabilidad
            </a>
            <router-link
              :to="{name: 'privacy-policy'}"
              target="_blank"
              style="color: white"
            >
              Política de Privacidad
            </router-link>
          </template>
          <template v-else>
            <a
              href="https://mandomedio.com/outplacement-personas/"
              style="color: white; margin: 0 1% 0 1%"
              target="_blank"
            >
              Empleabilidad
            </a>
            <router-link
              :to="{name: 'privacy-policy'}"
              target="_blank"
              style="color: white"
            >
              Privacidad
            </router-link>
          </template>
          <v-spacer />
          <a
            href="https://mandomedio.com"
            style="color: white; margin: 0 1% 0 1%"
            target="_blank"
          >
            &copy; Mandomedio 2019
          </a>
        </v-footer>
      </slot> -->
    </template>

    <LoadingComponent />
    <GenericMessageDialog />
    <ConsentSnackbar v-if="false" />
    <GenericSnackbar />
  </v-app>
</template>

<script>
import Toolbar from '@/components/candidates/Toolbar.vue';
import GenericMessageDialog from '@/components/misc/GenericMessageDialog.vue';
import LoadingComponent from '@/components/misc/LoadingComponent.vue';
import GenericSnackbar from '@/components/misc/GenericSnackbar.vue';
import ConsentSnackbar from '@/components/candidates/ConsentSnackbar.vue';

export default {
  components: {
    Toolbar,
    GenericMessageDialog,
    LoadingComponent,
    GenericSnackbar,
    ConsentSnackbar,
  },
  props: {
    source: {
      type: String,
      default: '',
    },
  },

  data: () => ({
  }),
};
</script>
