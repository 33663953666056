<script>
export default {
  name: 'MarketplaceLinkMixin',
  methods: {
    marketplaceLink(isRegister) {
      let url = 'https://plataforma.mandomedio.com/';
      if (isRegister) {
        url += '?register=true';
      }
      return url;
    },
  },
};
</script>
