<template>
  <v-toolbar-items>
    <v-menu
      transition="slide-y-transition"
      bottom
    >
      <template v-slot:activator="{ on }">
        <v-btn
          flat
          icon
          color="grey"
          v-on="on"
        >
          <v-icon>
            menu
          </v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-tile
          avatar
          ripple
          :to="{name: 'candidate:profile:detail'}"
        >
          <v-avatar
            size="27"
            class="mr-2"
          >
            <img :src="`${profilePic}`">
          </v-avatar>

          <v-list-tile-content>
            <v-list-tile-title>
              {{ $store.state.fullname }}
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-divider />

        <v-list-tile
          :to="{name: 'candidate:profile:settings'}"
          style="margin: 0"
        >
          <span
            class="mr-3 mt-1"
            style="margin: 0;"
          >
            <v-icon small>fas fa-cog</v-icon>
          </span>
          <v-list-tile-content style="margin: 0">
            Configuración
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile
          style="margin: 0"
          @click="logout"
        >
          <span
            style="margin: 0"
            class="mr-3 mt-1"
          >
            <v-icon small>exit_to_app</v-icon>
          </span>
          <v-list-tile-content>
            Cerrar sesión
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-menu>
  </v-toolbar-items>
</template>

<script>
import ProfilePicComputedMixin from '@/components/mixins/auth/ProfilePicComputedMixin.vue';
import LogoutMethodMixin from '@/components/mixins/auth/LogoutMethodMixin.vue';

export default {
  name: 'ToolbarLinksMobileLogged',
  mixins: [
    ProfilePicComputedMixin,
    LogoutMethodMixin,
  ],
};
</script>
