<template>
  <v-toolbar-items>
    <!--<v-btn
      flat
      :to="{name: 'faq:list'}"
      target="_blank"
    >
      <p class="text-none help-text">
        Ayuda
      </p>
    </v-btn>
    -->
    <v-menu
      :min-width="130"
    >
      <v-btn
        slot="activator"
        flat
        dark
        class="primary--text"
      >
        <v-avatar>
          <img :src="`${profilePic}`">
        </v-avatar>
      </v-btn>

      <v-list two-line>
        <v-list-tile
          avatar
          ripple
          :to="{name: 'candidate:profile:detail'}"
        >
          <v-avatar
            size="35"
            class="mr-1"
          >
            <img :src="`${profilePic}`">
          </v-avatar>
          <v-list-tile-content>
            <v-list-tile-title>
              {{ $store.state.fullname }}
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>

        <v-divider />

        <v-list-tile
          :to="{name: 'candidate:profile:settings'}"
          style="margin: 0"
        >
          <span
            class="mr-3 mt-1"
            style="margin: 0;"
          >
            <v-icon>fas fa-cog</v-icon>
          </span>
          <v-list-tile-content style="margin: 0">
            Configuración
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile
          style="margin: 0"
          @click="logout"
        >
          <span
            style="margin: 0"
            class="mr-3 mt-1"
          >
            <v-icon>exit_to_app</v-icon>
          </span>
          <v-list-tile-content>
            Cerrar sesión
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-menu>

    <GeoCountrySelector
      :geo="geo"
    />
  </v-toolbar-items>
</template>

<script>
import LogoutMethodMixin from '@/components/mixins/auth/LogoutMethodMixin.vue';
import ProfilePicComputedMixin from '@/components/mixins/auth/ProfilePicComputedMixin.vue';
import GeoComputedMixin from '@/components/mixins/geo/GeoComputedMixin.vue';
import GeoCountrySelector from '@/components/misc/GeoCountrySelector.vue';

export default {
  name: 'ToolbarLinksDesktopLogged',
  components: {
    GeoCountrySelector,
  },
  mixins: [
    LogoutMethodMixin,
    ProfilePicComputedMixin,
    GeoComputedMixin,
  ],
};
</script>

<style scoped>
.help-text {
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  font-family: Lato;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 28px;
}
</style>
