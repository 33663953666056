<script>
export default {
  name: 'ProfilePicComputedMixin',
  computed: {
    profilePic() {
      return (this.$store.state.profilePic) ? this.$store.state.profilePic : 'https://avataaars.io/';
    },
  },
};
</script>
