<template>
  <div>
    <v-btn
      class="primary--text"
      flat
      style="border-color: #00b2c1 !important; color: #00b2c1 !important;"
      @click="helpClick"
    >
      Ayuda
    </v-btn>
    <v-menu
      open-on-hover
      offset-y
    >
      <v-btn
        slot="activator"
        class="primary--text"
        outline
        style="border-color: #00b2c1 !important; color: #00b2c1 !important;"
      >
        Iniciar Sesión
      </v-btn>
      <v-list>
        <v-list-tile
          @click="login_dialog = true; login_type = 'normal'"
        >
          <v-list-tile-title>Candidatos</v-list-tile-title>
        </v-list-tile>
        <v-list-tile
          :href="marketplaceLink(false)"
          target="_blank"
        >
          <v-list-tile-title>Clientes</v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-menu>

    <v-menu
      open-on-hover
      offset-y
    >
      <v-btn
        slot="activator"
        class="primary"
        flat
        style="border-color: #00b2c1 !important; background-color: #00b2c1 !important;"
      >
        Registrarse
      </v-btn>
      <v-list
        style="background-color: #00b2c1 !important; color: white !important;"
      >
        <v-list-tile
          @click="signup_modal = true; login_type = 'normal'"
        >
          <v-list-tile-title>Candidatos</v-list-tile-title>
        </v-list-tile>
        <v-list-tile
          :href="marketplaceLink(true)"
          target="_blank"
        >
          <v-list-tile-title>Clientes</v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-menu>
    <v-dialog
      v-model="login_dialog"
      max-width="432"
      style="margin: 0px;"
      persistent
    >
      <LoginCard
        :login-type="login_type"
        @closeLogin="login_dialog = false"
        @loginSuccess="loginSuccess"
        @changeModal="signup_modal = true; login_dialog = false"
      />
    </v-dialog>
    <v-dialog
      v-model="signup_modal"
      max-width="432"
      style="margin: 0px"
      persistent
    >
      <CandidateSignup
        @signupDone="finishSignup"
        @closeModal="signup_modal = false;"
        @changeModal="signup_modal = false; login_dialog = true"
      />
    </v-dialog>
  </div>
</template>

<script>
import LoginCard from '@/components/forms/LoginCard.vue';
import CandidateSignup from '@/components/forms/CandidateSignup.vue';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';
import MarketplaceLinkMixin from '@/components/mixins/marketplace/MarketplaceLinkMixin.vue';
import LogoutMethodMixin from '@/components/mixins/auth/LogoutMethodMixin.vue';
import UserIsLoggedComputedMixin from '@/components/mixins/auth/UserIsLoggedComputedMixin.vue';
import AuthenticatePayloadMethodMixin from '@/components/mixins/auth/AuthenticatePayloadMethodMixin.vue';

export default {
  name: 'ToolbarLinksDesktopNotLogged',
  components: {
    LoginCard,
    CandidateSignup,
  },
  mixins: [
    JWTConfigMixin,
    MarketplaceLinkMixin,
    LogoutMethodMixin,
    UserIsLoggedComputedMixin,
    AuthenticatePayloadMethodMixin,
  ],
  data() {
    return {
      login_dialog: false,
      signup_modal: false,
      login_type: '',
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.validateQuery();
    });
  },
  methods: {
    validateQuery() {
      if (this.$route.query.next === 'next') {
        this.login_dialog = true;
      }
    },
    finishSignup(payload) {
      this.signup_modal = false;
      this.$emit('signupDone', '');
      this.authenticate(payload); // AuthenticatePayloadMethodMixin
    },
    loginSuccess() {
      this.login_dialog = false;
      if (this.login_type === 'admin' && this.userIsLoggedIn) {
        if (JSON.parse(window.localStorage.vuex).isAdmin) {
          this.$router.push({ name: 'admin:offers' });
        } else {
          // alert('no tiene permiso para entrar');
          this.logout();
        }
      }
    },
    helpClick() {
      this.$ga.event('helpButton', 'Help Button Not Logged', 'Help Button Not Logged', 1);
      this.$router.push({ name: 'faq:list' });
    },
  },
};
</script>
