<template>
  <ToolbarLinksDesktopLogged v-if="userIsLoggedIn" />
  <ToolbarLinksDesktopNotLogged v-else />
</template>

<script>
import ToolbarLinksDesktopLogged from '@/components/candidates/ToolbarLinksDesktopLogged.vue';
import ToolbarLinksDesktopNotLogged from '@/components/candidates/ToolbarLinksDesktopNotLogged.vue';
import UserIsLoggedComputedMixin from '@/components/mixins/auth/UserIsLoggedComputedMixin.vue';

export default {
  name: 'ToolbarLinksDesktop',
  components: {
    ToolbarLinksDesktopLogged,
    ToolbarLinksDesktopNotLogged,
  },
  mixins: [
    UserIsLoggedComputedMixin,
  ],
};
</script>
