<template>
  <ToolbarLinksMobileLogged v-if="userIsLoggedIn" />
  <ToolbarLinksMobileNotLogged v-else />
</template>

<script>
import ToolbarLinksMobileLogged from '@/components/candidates/ToolbarLinksMobileLogged.vue';
import ToolbarLinksMobileNotLogged from '@/components/candidates/ToolbarLinksMobileNotLogged.vue';
import UserIsLoggedComputedMixin from '@/components/mixins/auth/UserIsLoggedComputedMixin.vue';

export default {
  name: 'ToolbarLinksMobile',
  components: {
    ToolbarLinksMobileLogged,
    ToolbarLinksMobileNotLogged,
  },
  mixins: [
    UserIsLoggedComputedMixin,
  ],
};
</script>
